<!-- 
	This is the sign in page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->

 <template>
	<div class="sign-in bg-gray-3">
		
		<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" style="padding-top: 50px;">

			<a-col :span="24" :xs="24" :sm="24" :md="18" :lg="16" :xl="14" class="col-form">
				
				<a-card :bordered="false" class="card-info px-5 mx-0 pt-20">
					<a-row :gutter="[24,24]">
						<a-col :span="24" :xs="24" :md="24" :lg="24">
							<a-row class="mt-0 pt-2 mb-20">

								<a-col :span="12" class="text-left pl-10">
									<a-button id="btnBordered" type="text" class="primary" v-if="(current > 0 && current <= 2) || current > 3" @click="previousScreen"><a-icon type="arrow-left" theme="outlined" /> &nbsp;&nbsp;  Back </a-button>
								</a-col>

								<!-- <a-col :span="12" class="text-right pr-10">
									<a-button id="btnBordered" type="text" class="primary" @click="skipScreen"> Skip <a-icon type="arrow-right" theme="outlined" /> &nbsp;&nbsp; </a-button>
								</a-col> -->
							</a-row>
							<!-- <a-steps :current="current">
								<a-step v-for="item in steps" :key="item.title" :title="item.title" />
							</a-steps> -->
							<a-row class="mt-0 pt-2">
								<a-col :span="24" class="px-10">
									<a-progress strokeColor="#734EBF" trailColor="success" :percent="Math.floor(((current + 1) / 7) * 100)" />
								</a-col>
							</a-row>
							<div class="steps-content">
								<!-- {{ steps[current].content }} -->
								<template>
									<a-row ty :gutter="[24,24]">
										
										<a-col :span="24" :md="24" :lg="24">

											<a-row type="flex" :gutter="[24,24]" justify="space-around" align="top" style="margin-top: 50px;" v-if="current==0">
												<a-col :span="24" :md="12" :lg="12" :xl="{span: 12, offset: 0}" class="col-form pt-2">

													<a-row ty :gutter="24" class="text-center">
														<a-col :span="24">
															<label class="text-center mt-0 pt-0" style="font-size: 18px;">
																<strong>Select targeted grades</strong>
															</label>
														</a-col>
													</a-row>

                                                    <a-row :gutter="[24,24]" v-if="!newClass.levelLoading">
                                                        <a-col :span="24" :sm="24" :md="24" :lg="24" class="mt-10">
                                                            <a-select  v-model="newClass.levelUuid" style="width: 100%;" placeholder="Select Education Level" @change="onSelectEducationLevel">
                                                                <a-select-option v-for="option in educationLevels"
                                                                    :value="option.uuid"
                                                                    :key="option.name">
                                                                    {{ option.name }}
                                                                </a-select-option>
                                                            </a-select>
                                                        </a-col>
                                                    </a-row>

													<div v-if="newClass.levelLoading">

                                                        <a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="" style="min-height: 10vh">

                                                            <a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

                                                                <a-spin class="text-primary" />

                                                            </a-col>

                                                        </a-row>
                                                    </div>


                                                    <a-row :gutter="[24,24]" v-if="!newClass.gradeLoading">
                                                        <a-col :span="24" class="mt-20">
                                                            <a-badge  
                                                                v-for="option in grades"
                                                                :key="option.uuid"
                                                                style="font-size: 14px !important; margin: 2px 3px; padding: 5px;" 
                                                                :count="`${option.name}`" 
                                                                @click="handleGradeSelect(option.uuid)"
                                                                :number-style="newClass.gradeUuid == option.uuid ? 
                                                                    {backgroundColor: '#734EBF !important', color: '#FFFFFF', paddingTop: '4px', height: '30px'} : 
                                                                    {border: '1px solid #734EBF !important', color: '#734EBF', backgroundColor: '#FFFFFF !important', paddingTop: '4px', height: '30px'}"/>
                                                        </a-col>
                                                    </a-row>

                                                    <div v-if="newClass.gradeLoading">

                                                        <a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="" style="min-height: 10vh">

                                                            <a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

                                                                <a-spin class="text-primary" />

                                                            </a-col>

                                                        </a-row>
                                                    </div>

                                                    <a-row :gutter="[24,24]">
                                                        <a-col :span="24" :sm="24" :md="24" :lg="24" class="mt-10 text-center">
                                                            <a-button :loading="newClass.gradeLoading" v-if="newClass.gradeUuid" type="primary" style="width: 100px !important;" block @click="submitGrades" class="login-form-button mt-10 text-white" >
                                                                Next
                                                            </a-button>
                                                        </a-col>
                                                    </a-row>

												</a-col>
											</a-row>


											<a-row type="flex" :gutter="[24,24]" justify="space-around" align="top" style="margin-top: 50px;" v-if="current==1">
												<a-col :span="24" :md="12" :lg="12" :xl="{span: 12, offset: 0}" class="col-form pt-2">

													<a-row ty :gutter="24" class="text-center">
														<a-col :span="24">
															<label class="text-center mt-0 pt-0" style="font-size: 18px;">
																<strong>Select the subjects you need to learn?</strong>
															</label>
														</a-col>
													</a-row>

													<a-row :gutter="[24,24]" v-if="!newClass.subjectLoading">
														<a-col :span="24" class="mt-20">
															<a-badge  
																v-for="option in subjects"
																:key="option.uuid"
																style="font-size: 14px !important; margin: 2px 3px; padding: 5px;" 
																:count="`${option.name}`" 
																@click="handleSubjectSelect(option.uuid)"
																:number-style="newClass.subjectUuid == option.uuid ? 
																	{backgroundColor: '#734EBF !important', color: '#FFFFFF', paddingTop: '4px', height: '30px'} : 
																	{border: '1px solid #734EBF !important', color: '#734EBF', backgroundColor: '#FFFFFF !important', paddingTop: '4px', height: '30px'}"/>
														</a-col>
													</a-row>

													<div v-if="newClass.subjectLoading">

														<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="" style="min-height: 10vh">

															<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

																<a-spin class="text-primary" />

															</a-col>

														</a-row>
													</div>

													<a-row ty :gutter="24" class="text-center mt-20">
														<a-col :span="24">
															<a-button :loading="newClass.subjectLoading" v-if="newClass.subjectUuid != null && newClass.subjectUuid.length > 0" type="primary" style="width: 100px !important;" block @click="submitSubjects" class="login-form-button mt-10 text-white" >
																Next
															</a-button>
														</a-col>
													</a-row>
													
												</a-col>
											</a-row>


											<a-row type="flex" :gutter="[24,24]" justify="space-around" align="top" style="margin-top: 20px;" v-if="current==2">
												<a-col :span="24" :md="22" :lg="22" :xl="{span: 22, offset: 0}" class="col-form pt-2">

													<a-row ty :gutter="24" class="text-center">
														<a-col :span="24">
															<label class="text-center mt-0 pt-0" style="font-size: 18px;">
																<strong>Update Class</strong>
															</label>
														</a-col>
													</a-row>

													<a-alert
														v-if="errorNewClass.show"
														:message="errorNewClass.message"
														type="error"
														closable
														:after-close="handleClose"
														show-icon 
													/>

													<a-row type="flex" :gutter="[24, 24]" justify="space-around" align="top" class="mt-20 mb-0 pb-0">
														<a-col :span="24" :md="14" :lg="14" :xl="{span: 14, offset: 0}" class="">

															<a-row :gutter="[24, 24]" class="mt-20 mb-0 pb-0">
																<a-col :span="24" class="my-0 py-0 text-center">
																	
                                                                    <a-button id="classIconBtn" @click="onSelectImage">
                                                                        <img id="previewClassIcon" style="margin: 0px; width: 115px; height: 115px;" v-if="newClass.thumbnail != null" :src="`${$FILE_BASE_URL}${newClass.thumbnail}`"/>
                                                                        <span v-if="newClass.thumbnail == null">
                                                                            <svg class="dark:text-white mb-5" style="width: 22px; color: #444444" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                                                <path stroke="currentColor" stroke-linejoin="round" stroke-width="2" d="M4 18V8c0-.6.4-1 1-1h1.5l1.7-1.7c.2-.2.4-.3.7-.3h6.2c.3 0 .5.1.7.3L17.5 7H19c.6 0 1 .4 1 1v10c0 .6-.4 1-1 1H5a1 1 0 0 1-1-1Z"/>
                                                                                <path stroke="currentColor" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
                                                                            </svg><br/>
                                                                            Add Photo
                                                                        </span>
                                                                    </a-button>

																</a-col>

                                                                <a-col :span="24" class="my-0 py-0 mt-10 text-center">
                                                                    <small>You can either upload a photo or use one of the avatars</small>
                                                                </a-col>

															</a-row>

															<a-row :gutter="[24, 24]" class="mt-20 pt-10 mb-10 pb-0">
																<a-col :span="24" class="my-0 py-0">
																	<a-input
                                                                        v-model="newClass.name"
																		type="text" 
																		placeholder="Class Name" />
																</a-col>
															</a-row>


                                                            <a-row :gutter="[24, 24]" class="mt-20 mb-10 pb-0 pt-10">
																<a-col :span="18" class="my-0 py-0" v-if="newClass.isPublic">
																	<label><strong>This class is public</strong></label><br/>
                                                                    <small>Anyone will be able to join this class</small>
																</a-col>

																<a-col :span="18" class="my-0 py-0" v-if="!newClass.isPublic">
																	<label><strong>This class is private</strong></label><br/>
                                                                    <small>Not anyone will be able to join this class</small>
																</a-col>

																<a-col :span="6" class="my-0 py-0 text-right">
																	<a-switch class="mt-10 pt-10" v-model="newClass.isPublic" />
																</a-col>
															</a-row>

															<a-row :gutter="[24, 24]" class="mt-20 mb-0 pb-0">
																<a-col :span="12" class="my-0 py-0">
																	
																</a-col>
															</a-row>
														</a-col>
													</a-row>


													<a-row class="mt-20 pt-10 px-20 mx-20 mb-10">
														<a-col :span="24" class="text-center pr-10">
															<a-button type="primary" :loading="newClass.loading" v-if="newClass.thumbnail != null && newClass.thumbnail != null" class="" @click="submitCreateClass"> Update Class Details<a-icon type="arrow-right" theme="outlined" /> &nbsp;&nbsp; </a-button>
														</a-col>
													</a-row>

												</a-col>
											</a-row>


                                            <a-row type="flex" :gutter="[24,24]" justify="space-around" align="top" style="margin-top: 20px;" v-if="current==3">
												<a-col :span="24" :md="22" :lg="22" :xl="{span: 22, offset: 0}" class="col-form pt-2">

													<a-row ty :gutter="24" class="text-center">
														<a-col :span="24">
															<label class="text-center mt-0 pt-0" style="font-size: 18px;">
																<strong>What is the class about?</strong><br/>
                                                                <small>A clear message informing all joining members of the learning goals</small>
															</label>
														</a-col>
													</a-row>

                                                    <a-alert
														v-if="aboutClass.errorShow"
														:message="aboutClass.errorMessage"
														type="error"
														closable
														:after-close="handleClose"
														show-icon 
													/>

													<a-row type="flex" :gutter="[24, 24]" justify="space-around" align="top" class="mt-0 mb-0 pb-0">
														<a-col :span="24" :md="14" :lg="14" :xl="{span: 14, offset: 0}" class="">

															<a-row :gutter="[24, 24]" class="mt-20 pt-10 mb-10 pb-0">
																<a-col :span="24" class="my-0 py-0">
																	<a-textarea 
                                                                        :rows="4"
                                                                        placeholder="Add Description" 
                                                                        v-model="aboutClass.objective" />
																</a-col>
															</a-row>

														</a-col>
													</a-row>


													<a-row class="mt-20 pt-0 px-20 mx-20 mb-10">
														<a-col :span="24" class="text-center pr-10">
															<a-button type="primary" :loading="aboutClass.loading" v-if="aboutClass.objective != null && aboutClass.objective.length > 10" class="" @click="submitAboutClass"> Next <a-icon type="arrow-right" theme="outlined" /> &nbsp;&nbsp; </a-button>
														</a-col>
													</a-row>

												</a-col>
											</a-row>


                                            <a-row type="flex" :gutter="[24,24]" justify="space-around" align="top" style="margin-top: 20px;" v-if="current==4">
												<a-col :span="24" :md="22" :lg="22" :xl="{span: 22, offset: 0}" class="col-form pt-2">

													<a-row type="flex" :gutter="[24, 24]" justify="space-around" align="top" class="mt-0 mb-0 pb-0 text-center">
														<a-col :span="24" :md="16" :lg="16" :xl="{span: 16, offset: 0}" class="">
															<label class="text-center mt-0 pt-0" style="font-size: 18px;">
																<strong>Specify the learning objectives</strong><br/>
                                                                <small>Provide clear and measurable statements that outline what students should be able to know, understand, or do after completing the course</small>
															</label>
														</a-col>
													</a-row>

                                                    <a-alert
														v-if="learningObjectives.errorShow"
														:message="learningObjectives.errorMessage"
														type="error"
														closable
														:after-close="handleClose"
														show-icon 
													/>

													<a-row type="flex" :gutter="[24, 24]" justify="space-around" align="top" class="mt-0 mb-0 pb-0">
														<a-col :span="24" :md="14" :lg="14" :xl="{span: 14, offset: 0}" class="">

															<a-row :gutter="24" class="mt-20 pt-10 mb-10 pb-0" v-for="(option, index) in learningObjectives.specificObjectives" :key="index">
																<a-col :span="20" class="my-0 py-0">
																	<a-textarea 
                                                                        :rows="4"
                                                                        placeholder="Add course objective e.g. To be able to calculate square root of numbers" 
                                                                        v-model="learningObjectives.specificObjectives[index]" />
																</a-col>

                                                                <a-col :span="4" class="text-center pr-10 pt-20 mt-10">
                                                                    <a-button type="danger" size="small" class="mx-0 px-0 pl-10" style="padding-right: 5px !important;" @click="removeObjective(index)"><a-icon type="cross" class="px-0 mx-0" theme="outlined" /></a-button>
                                                                </a-col>
															</a-row>


                                                            <a-row class="mt-20 pt-0 px-20 mx-20 mb-10">
                                                                <a-col :span="24" class="text-center pr-10">
                                                                    <a-button type="primary" class="" @click="addObjective"><a-icon type="plus" theme="outlined" /></a-button>
                                                                </a-col>
                                                            </a-row>

														</a-col>
													</a-row>


													<a-row class="mt-20 pt-20 px-20 mx-20 mb-10">
														<a-col :span="24" class="text-center pr-10">
															<a-button :loading="learningObjectives.loading" type="primary" v-if="learningObjectives.specificObjectives != null && learningObjectives.specificObjectives.length > 0" class="" @click="submitLearningObjectives"> Next <a-icon type="arrow-right" theme="outlined" /> &nbsp;&nbsp; </a-button>
														</a-col>
													</a-row>

												</a-col>
											</a-row>


											<a-row type="flex" :gutter="[24,24]" justify="space-around" align="top" style="margin-top: 20px;" v-if="current==5">
												<a-col :span="24" :md="22" :lg="22" :xl="{span: 22, offset: 0}" class="col-form pt-2">

													<a-row type="flex" :gutter="[24, 24]" justify="space-around" align="top" class="mt-0 mb-0 pb-0 text-center">
														<a-col :span="24" :md="16" :lg="16" :xl="{span: 16, offset: 0}" class="">
															<label class="text-center mt-0 pt-0" style="font-size: 18px;">
																<strong>What does your class contain?</strong><br/>
                                                                <small>To help students understand what they will get fro this class, select what’s applicable</small>
															</label>
														</a-col>
													</a-row>

                                                    <a-alert
														v-if="classFeatures.errorShow"
														:message="classFeatures.errorMessage"
														type="error"
														closable
														:after-close="handleClose"
														show-icon 
													/>

													<a-row type="flex" :gutter="[24, 24]" justify="space-around" align="top" class="mt-0 mb-0 pb-0">
														<a-col :span="24" :md="14" :lg="14" :xl="{span: 14, offset: 0}" class="">

															<a-row :gutter="24" class="mt-20 pt-10 mb-10 pb-0 text-center">
																<a-col :span="8" class="my-0 py-0" @click="onSelectFeatures('live_class')">
																	<label style="font-weight: 600">Live Classes</label><br/>
																	<a-avatar :size="70" class="mt-10" :style="classFeatures.features.includes('live_class') ? 'background: #734EBF' : 'background: #CCCCCC'"><span style="font-size: 35px">📹</span></a-avatar>
																</a-col>

																<a-col :span="8" class="my-0 py-0" @click="onSelectFeatures('notes')">
																	<label style="font-weight: 600">Notes</label><br/>
																	<a-avatar :size="70" class="mt-10" :style="classFeatures.features.includes('notes') ? 'background: #734EBF' : 'background: #CCCCCC'"><span style="font-size: 35px">📖</span></a-avatar>
																</a-col>

																<a-col :span="8" class="my-0 py-0" @click="onSelectFeatures('past_papers')">
																	<label style="font-weight: 600">Past Exams</label><br/>
																	<a-avatar :size="70" class="mt-10" :style="classFeatures.features.includes('past_papers') ? 'background: #734EBF' : 'background: #CCCCCC'"><span style="font-size: 35px">📝</span></a-avatar>
																</a-col>
                                                                
															</a-row>

															<a-row :gutter="24" class="mt-20 pt-20 mb-10 pb-0 text-center">
																<a-col :span="8" class="my-0 py-0" @click="onSelectFeatures('session_recording')">
																	<label style="font-weight: 600">Lifetime Recordings</label><br/>
																	<a-avatar :size="70" class="mt-10" :style="classFeatures.features.includes('session_recording') ? 'background: #734EBF' : 'background: #CCCCCC'"><span style="font-size: 35px">💾</span></a-avatar>
																</a-col>

																<a-col :span="8" class="my-0 py-0" @click="onSelectFeatures('live_chat')">
																	<label style="font-weight: 600">Live Chats</label><br/>
																	<a-avatar :size="70" class="mt-10" :style="classFeatures.features.includes('live_chat') ? 'background: #734EBF' : 'background: #CCCCCC'"><span style="font-size: 35px">💬</span></a-avatar>
																</a-col>

																<a-col :span="8" class="my-0 py-0" @click="onSelectFeatures('quiz')">
																	<label style="font-weight: 600">Home work</label><br/>
																	<a-avatar :size="70" class="mt-10" :style="classFeatures.features.includes('quiz') ? 'background: #734EBF' : 'background: #CCCCCC'"><span style="font-size: 35px">🏠</span></a-avatar>
																</a-col>
                                                                
															</a-row>

														</a-col>
													</a-row>


													<a-row class="mt-20 pt-20 px-20 mx-20 mb-10">
														<a-col :span="24" class="text-center pr-10">
															<a-button :loading="classFeatures.loading" type="primary" v-if="classFeatures.features != null && classFeatures.features.length > 0" class="" @click="submitClassFeatures"> Next <a-icon type="arrow-right" theme="outlined" /> &nbsp;&nbsp; </a-button>
														</a-col>
													</a-row>

												</a-col>
											</a-row>


											<a-row type="flex" :gutter="[24,24]" justify="space-around" align="top" style="margin-top: 20px;" v-if="current==6">
												<a-col :span="24" :md="22" :lg="22" :xl="{span: 22, offset: 0}" class="col-form pt-2">

													<a-row type="flex" :gutter="[24, 24]" justify="space-around" align="top" class="mt-0 mb-0 pb-0 text-center">
														<a-col :span="24" :md="16" :lg="16" :xl="{span: 16, offset: 0}" class="">
															<label class="text-center mt-0 pt-0" style="font-size: 18px;">
																<strong>Specify number of students and price for this class?</strong><br/>
                                                                <small>Give clear number of students you need and if this class is paid then set the class price</small>
															</label>
														</a-col>
													</a-row>

                                                    <a-alert
														class="my-10"
														v-if="classPrice.errorShow"
														:message="classPrice.errorMessage"
														type="error"
														closable
														:after-close="handleClose"
														show-icon 
													/>

													<a-row type="flex" :gutter="[24, 24]" justify="space-around" align="top" class="mt-0 mb-0 pb-0">
														<a-col :span="24" :md="14" :lg="14" :xl="{span: 14, offset: 0}" class="">

															<a-row :gutter="24" class="mt-0 pt-10 mb-10 pb-0 text-center">
																<a-col :span="24" class="my-0 py-0">
																	<a-input
                                                                        v-model="classPrice.numOfSlots"
																		type="number" 
																		placeholder="Enter number of students here" />
																</a-col>
															</a-row>

															<a-row :gutter="24" class="mt-20 pt-10 mb-10 pb-0">

																<a-col :span="18" class="my-0 py-0" v-if="!classPrice.isPaid">
																	<label><strong>This class is free</strong></label><br/>
                                                                    <small>Students can join and access content free</small>
																</a-col>

																<a-col :span="18" class="my-0 py-0" v-if="classPrice.isPaid">
																	<label><strong>This class is paid</strong></label><br/>
                                                                    <small>Students pay the set price to join this class</small>
																</a-col>

																<a-col :span="6" class="my-0 py-0 text-right">
																	<a-switch class="mt-10 pt-10" v-model="classPrice.isPaid" @change="onPriceChange($event)"/>
																</a-col>

																<a-col :span="24" class="mt-20 py-0" v-if="classPrice.isPaid">
																	<a-input
                                                                        v-model="classPrice.price"
																		type="number" 
																		placeholder="Class Enter the class price" />
																	<small>Price must range between 5,000/=Tzs - 50,000/=Tzs</small>
																</a-col>

															</a-row>

														</a-col>
													</a-row>


													<a-row class="mt-20 pt-20 px-20 mx-20 mb-10">
														<a-col :span="24" class="text-center pr-10">
															<a-button :loading="classPrice.loading" type="primary" v-if="classPrice.numOfSlots != null" class="" @click="submitClassPrice"> Next <a-icon type="arrow-right" theme="outlined" /> &nbsp;&nbsp; </a-button>
														</a-col>
													</a-row>

												</a-col>
											</a-row>
		
										</a-col>
									</a-row>
								</template>
							</div>
							<div class="steps-action">
								<!-- <a-button v-if="current < steps.length - 1" type="primary" @click="next">Next</a-button>
								
								<a-button v-if="current > 0" style="margin-left: 8px" @click="prev">Previous</a-button> -->
							</div>
						</a-col>
					</a-row>

				</a-card>
			</a-col>


		</a-row>


		<!-- AVATAR MODAL -->
		<a-modal v-model="newClass.avatarShowModal" :footer="null" :width="1080">
			
			<a-row :gutter="[24, 24]" class="mt-20 mb-0 pb-0">
				<a-col :span="24" class="my-0 py-0">
					<h6 style="font-weight: normal; font-size: 18px;" class="text-center">Select Avatar</h6>
				</a-col>
			</a-row>

            <a-row :gutter="[24, 24]" class="mt-10 mb-0 pb-0" v-if="!newClass.avatarLoading">
				<a-col :span="6" class="" v-for="option in avatars" :key="option.uuid">
                    <img @click="onSelectedImage(option)" id="classImg" width="100%" height="150px" :src="option.icon" alt="">
                </a-col>
			</a-row>


			<div v-if="newClass.avatarLoading">
				<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="" style="min-height: 40vh">
					<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">
						<a-spin class="text-primary" />
					</a-col>
				</a-row>
			</div>


		</a-modal>
		
	</div>
</template>

<script>
    import { notification } from 'ant-design-vue';
    import VueCountryCode from "vue-country-code-select";

	export default ({
		components: {
			VueCountryCode
		},

		data() {
			return {
	
				current: 0,
				steps: [
					{
						title: 'Target Grades',
						content: 'Third-content',
					},
					{
						title: 'Subject',
						content: 'Fourth-content',
					},
					{
						title: 'Update Class',
						content: 'Fifth-content',
					},
					{
						title: 'About',
						content: 'Fifth-content',
					},
					{
						title: 'Objectives',
						content: 'Fifth-content',
					},
					{
						title: 'Features',
						content: 'Fifth-content',
					},
					{
						title: 'Slots & Price',
						content: 'Fifth-content',
					},
				],

				user: {},
				institutionDetails: {},

				academicEducationSystems: [],
				nonAcademicEducationSystems: [],

				grades: [],
				subjects: [],
				educationLevels: [],
				educationSystems: [],
				avatars: [],

                newClass: {
					classUuid: null,
                    name: null,
                    icon: null,
                    isPublic: true,
                    thumbnail: null,
					systemUuid: undefined,
					levelUuid: undefined,
                    gradeUuid: null,
                    subjectUuid: null,
					educationSystems: [],
					educationLevels: [],
					educationGrades: [],
					subjects: [],

                    loading: false,
					levelLoading: false,
                    gradeLoading: false,
                    subjectLoading: false,
					avatarShowModal: false,
					avatarLoading: false,
                },

				errorNewClass: {
					message: null,
					show: false,
				},

                aboutClass: {
                    objective: null,
                    loading: false,
                    errorShow: false,
                    errorMessage: null,
                },

                learningObjectives: {
                    specificObjectives: [],
                    loading: false,
                    errorShow: false,
                    errorMessage: null,
                },

                classFeatures: {
                    features: [],
                    loading: false,
                    errorShow: false,
                    errorMessage: null,
                },

                assessmentModes: {
                    modes: [],
                    loading: false,
                    errorShow: false,
                    errorMessage: null,
                },

                classPrice: {
                    numOfSlots: null,
                    isPaid: false,
                    price: 0,
                    loading: false,
                    errorShow: false,
                    errorMessage: null,
                },
				
			}
		},
		async mounted () {
			let breadcrumbs = [
				{ title: 'Classes', url: '/institutions/classes', isActive: false },
                { title: 'Classroom', url: `/institutions/my-classes/view/${this.$route.params.uuid}`, isActive: false },
                { title: 'Update Class', url: ``, isActive: true },
			];
			
			await this.$root.$refs.Breadcrumbs.initializeBreadCrumbs(breadcrumbs)
		},
		beforeCreate() {
			// Creates the form and adds to it component's "form" property.
			// this.personalDetailsForm = this.$form.createForm(this, { name: 'personal_details' });
			this.educationSystemForm = this.$form.createForm(this, { name: 'education_system_form' });
			this.gradeForm = this.$form.createForm(this, { name: 'grade_form' });
		},
		created() {
			this.getInstitutionDetails();
			this.getClassDetails();
			// this.getEducationSystems();
			this.getEducationLevels();
		},
		methods: {

			nextScreen() {
				this.current++;
			},

			previousScreen() {
				if(this.current > 0 && this.current <= 2) {
					this.current--;
				}

				if(this.current > 3) {
					this.current--;
				}
			},

			skipScreen() {
				this.skipConfirmation.showModal = true;
			},

			cancelModal() {
				this.skipConfirmation.showModal = false;
			},

			toHomePage() {
				this.$router.push("/home")
			},


			handleLearningSelection(isAcademic) {
				this.learning.isAcademic = isAcademic;
			},

			handleClose() {
				this.error.show = false;
			},
                    

			async getClassDetails() {

				this.loadingData = true;

                let url = `${this.$BACKEND_URL}/classes/?uuid=${this.$route.params.uuid}&populate=true`;

                this.$AXIOS.get(url).then(async(response) => {
                    if (response.status == 200) {
						this.newClass.classUuid = response.data.data.uuid;
                        this.newClass.name = response.data.data.name;
						this.newClass.isPublic =  response.data.data.isPublic;
						this.newClass.thumbnail =  response.data.data.thumbnail.replace(this.$FILE_BASE_URL, '');
						this.newClass.systemUuid =  response.data.data.educationSystems != null && response.data.data.educationSystems.length > 0 ? response.data.data.educationSystems[0].uuid : [];
						this.newClass.levelUuid =  response.data.data.educationLevels != null && response.data.data.educationLevels.length > 0 ? response.data.data.educationLevels[0].uuid : [];
						this.newClass.gradeUuid =  response.data.data.educationGrades != null && response.data.data.educationGrades.length > 0 ? response.data.data.educationGrades[0].uuid : [];
						this.newClass.subjectUuid =  response.data.data.subjects != null && response.data.data.subjects.length > 0 ? response.data.data.subjects[0].uuid : [];
						// this.newClass.educationSystems =  response.data.data.educationSystems;
						// this.newClass.educationLevels =  response.data.data.educationLevels;
						// this.newClass.educationGrades =  response.data.data.educationGrades;
						// this.newClass.subjects =  response.data.data.subjects;

						this.aboutClass.objective = response.data.data.objective;
						this.learningObjectives.specificObjectives = response.data.data.specificObjectives;
						this.classFeatures.features = response.data.data.features;
						this.assessmentModes.modes = response.data.data.assessmentModes;
						this.classPrice.price = response.data.data.price;
						this.classPrice.isPaid = response.data.data.price != 0;
						this.classPrice.numOfSlots = response.data.data.numOfSlots;

                        // this.newClass.objective = response.data.data.objective;
                        // this.newClass.isPublic = response.data.data.isPublic;
                        // this.newClass.educationSystems = response.data.data.educationSystems;
                        // this.newClass.educationLevels = response.data.data.educationLevels;
                        // this.newClass.educationGrades = response.data.data.educationGrades;
                        // this.newClass.subjects = response.data.data.subjects;
                        // this.newClass.numOfSlots = response.data.data.numOfSlots;
                        // this.newClass.rating = response.data.data.rating;
                        // this.newClass.creator = response.data.data.creator;
                        // this.newClass.setupCompleted = response.data.data.setupCompleted;
                        // this.newClass.isAcademic = response.data.data.isAcademic;
                        // this.newClass.price = response.data.data.price;
                        // this.newClass.isForTeaching = response.data.data.isForTeaching;
                        // this.newClass.features = response.data.data.features;
                        // this.newClass.assessmentModes = response.data.data.assessmentModes;
                        // this.newClass.startTimestamp = response.data.data.startTimestamp;
                        // this.newClass.endTimestamp = response.data.data.endTimestamp;
                        // this.newClass.thumbnail = response.data.data.thumbnail;
                        // this.newClass.createdAt = response.data.data.createdAt;
                        // this.newClass.updatedAt = response.data.data.updatedAt;

						await localStorage.setItem("class_name", response.data.data.name)
                    }
					
					this.loadingData = false;
					
                }).catch(async(error) => {
					
					this.loadingData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },

			// async getUserDetails() {

			// 	const userInfo = await localStorage.getItem('user_details')

			// 	if(userInfo != null) {
			// 		let userDetails = JSON.parse(userInfo);

			// 		this.user = userDetails;

			// 		this.newClass.creator = userDetails.uuid

			// 		let url = `${this.$BACKEND_URL}/profiles?uuid=${userDetails.uuid}`;

			// 		this.$AXIOS.get(url).then((response) => {

			// 			if(response.data.data != null) {
			// 				this.newClass.educationSystems = response.data.data.educationSystems;
			// 				this.getEducationLevels(response.data.data.educationSystems.length > 0 ? response.data.data.educationSystems[0] : null)
			// 			}else {
			// 				this.getEducationLevels(null)
			// 			}
					
			// 		}).catch(error => {

			// 			this.notify('error', error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!");
					
			// 		})

			// 	}else{
			// 		this.getEducationLevels(null)
			// 	}
				
				
			// },



			async getInstitutionDetails() {

				const institutionInfo = await localStorage.getItem('institution_details')

				if(institutionInfo != null) {
					let institutionDetails = JSON.parse(institutionInfo);

					console.log(institutionDetails)

					this.institutionDetails = institutionDetails;

					this.newClass.institution = institutionDetails.uuid;
					this.newClass.creator = institutionDetails.uuid;

					this.newClass.educationSystems = [institutionDetails.educationSystems[0]];

					this.getEducationLevels(institutionDetails.educationSystems[0]);
				
				}

				const userInfo = await localStorage.getItem('user_details')

				if(userInfo != null) {
					let userDetails = JSON.parse(userInfo);

					this.newClass.creator = userDetails.uuid
				}
				
			},



			async getEducationLevels(educationSystemUuid) {
                //educationSystemUuid

				this.newClass.levelLoading = true;

				const currentPage = 1;
				const perPage = 1000;

				let url = '';

				if(educationSystemUuid != null) {
					url = `${this.$BACKEND_URL}/education/levels?educationSystem=${educationSystemUuid}&page=${currentPage}&size=${perPage}`;
				}else{
                	url = `${this.$BACKEND_URL}/education/levels?&page=${currentPage}&size=${perPage}`;
				}

				this.$AXIOS.get(url).then((response) => {

					this.newClass.levelLoading = false;

					this.educationLevels = response.data.data;

				}).catch(error => {

					this.newClass.levelLoading = false;
		
					this.notify('error', error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!");
				
				})
			},




			async getGrades(levelUuid) {

				this.newClass.gradeLoading = true;

				const currentPage = 1;
				const perPage = 1000;

				let url = `${this.$BACKEND_URL}/education/grades?educationLevel=${levelUuid}&page=${currentPage}&size=${perPage}`;

				this.$AXIOS.get(url).then((response) => {

					this.grades = response.data.data;

					this.newClass.gradeLoading = false;

				}).catch(error => {

					this.newClass.gradeLoading = false;
		
					this.notify('error', error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!");
				
				})
			},


			async getSubjects() {

				this.newClass.subjectLoading = true;

				const currentPage = 1;
				const perPage = 1000;

				let url = `${this.$BACKEND_URL}/education/subjects?educationLevels=${this.newClass.levelUuid}&page=${currentPage}&size=${perPage}`;

				this.$AXIOS.get(url).then((response) => {

					this.subjects = response.data.data;

					this.newClass.subjectLoading = false;

				}).catch(error => {

					this.newClass.subjectLoading = false;
		
					this.notify('error', error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!");
				
				})
			},


			async getAvatars() {

				this.newClass.avatarLoading = true;

				const currentPage = 1;
				const perPage = 1000;

				let url = `${this.$BACKEND_URL}/avatars?subjects=${this.newClass.subjectUuid}&page=${currentPage}&size=${perPage}`;

				this.$AXIOS.get(url).then((response) => {

					this.avatars = response.data.data;

					this.newClass.avatarLoading = false;

				}).catch(error => {

					this.newClass.avatarLoading = false;
		
					this.notify('error', error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!");
				
				})
			},


			onSelectEducationLevel(event) {
				this.grades = [];

				this.newClass.gradeUuid = null;	
				this.newClass.levelUuid = event;
				
				this.getGrades(event)
			},


			handleGradeSelect(uuid) {
				this.newClass.gradeUuid = uuid;
			},


			submitGrades() {
	
                this.getSubjects();

                this.nextScreen();
				
			},


			handleSubjectSelect(uuid) {
				this.newClass.subjectUuid = uuid
			},


			submitSubjects() {
				this.getAvatars(); 

				this.nextScreen();
			},


            onSelectImage() {
                this.newClass.avatarLoading = true;

                this.newClass.avatarShowModal = true;

				setTimeout(() => this.newClass.avatarLoading = false, 2000);
            },


			onSelectedImage(avatar) {
				this.newClass.thumbnail = avatar.iconPath;
                this.newClass.avatarShowModal = false;
            },


			submitCreateClass() {

				this.newClass.loading = true;

				// this.newClass.educationSystems.push(this.newClass.);
				this.newClass.educationLevels.push(this.newClass.levelUuid);
				this.newClass.educationGrades.push(this.newClass.gradeUuid);
				this.newClass.subjects.push(this.newClass.subjectUuid);

				let url = `${this.$BACKEND_URL}/classes/${this.$route.params.uuid}`;

				this.$AXIOS.patch(url, this.newClass).then(async(response) => {

					this.notify('Class has been updated successfully', 'success')

					this.newClass.classUuid = response.data.data.uuid; 

					this.newClass.loading = false;

					this.nextScreen();

				}).catch(async(error) => {
					
					this.newClass.loading = false;

					this.newClass.errorMessage = error.response != null  && error.response.data != null ? error.response.data.message : "Connection error";
					this.newClass.errorShow = true

					if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

						this.$router.push(`/sign-in`);
					}
				});

			},

            submitAboutClass() {

				this.aboutClass.loading = true;

				let url = `${this.$BACKEND_URL}/classes/${this.newClass.classUuid}`;

				this.$AXIOS.patch(url, this.aboutClass).then(async(response) => {

					this.notify('Class details have been saved successfully', 'success')

					this.learningObjectives.specificObjectives.push(null)

					this.aboutClass.loading = false;

                	this.nextScreen()

				}).catch(async(error) => {
					
					this.aboutClass.loading = false;

					this.aboutClass.errorMessage = error.response != null  && error.response.data != null ? error.response.data.message : "Connection error";
					this.aboutClass.errorShow = true

					if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

						this.$router.push(`/sign-in`);
					}
				});
			},

            addObjective() {
                this.learningObjectives.specificObjectives.push(null)
			},

            removeObjective(index) {
                this.learningObjectives.specificObjectives.splice(index, 1)
			},

            submitLearningObjectives() {

                this.learningObjectives.loading = true;

				let url = `${this.$BACKEND_URL}/classes/${this.newClass.classUuid}`;

				this.$AXIOS.patch(url, this.learningObjectives).then(async(response) => {

					this.notify('Class details have been saved successfully', 'success')

					this.learningObjectives.loading = false;

                	this.nextScreen()

				}).catch(async(error) => {
					
					this.learningObjectives.loading = false;

					this.learningObjectives.errorMessage = error.response != null  && error.response.data != null ? error.response.data.message : "Connection error";
					this.learningObjectives.errorShow = true

					if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

						this.$router.push(`/sign-in`);
					}
				});
            },


			onSelectFeatures(feature) {
				if(this.classFeatures.features != null && this.classFeatures.features.includes(feature)) {
					this.classFeatures.features.splice(this.classFeatures.features.indexOf(feature), 1);
				}else{
					this.classFeatures.features.push(feature)
				}
			},


			submitClassFeatures() {

                this.classFeatures.loading = true;

				let url = `${this.$BACKEND_URL}/classes/${this.newClass.classUuid}`;

				this.$AXIOS.patch(url, this.classFeatures).then(async(response) => {

					this.notify('Class details have been saved successfully', 'success')

					this.classFeatures.loading = false;

                	this.nextScreen()

				}).catch(async(error) => {

					console.log(error)
					
					this.classFeatures.loading = false;

					this.classFeatures.errorMessage = error.response != null  && error.response.data != null ? error.response.data.message : "Connection error";
					this.classFeatures.errorShow = true

					if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

						this.$router.push(`/sign-in`);
					}
				});
            },


			onPriceChange(event) {
				if(!event) {
					this.classPrice.price = 0;
				}
			},


			submitClassPrice() {

				if((!this.institutionDetails.isAcademic) || (!this.classPrice.isPaid || (this.classPrice.isPaid && this.classPrice.price >= 5000 && this.classPrice.price <= 50000))) {

					this.classPrice.loading = true;

					let url = `${this.$BACKEND_URL}/classes/${this.newClass.classUuid}`;

					this.$AXIOS.patch(url, this.classPrice).then(async(response) => {

						this.notify('Class details have been saved successfully', 'success')

						this.classPrice.loading = false;

						this.$router.push(`/institutions/my-classes/view/${this.newClass.classUuid}`)

					}).catch(async(error) => {

						console.log(error)
						
						this.classPrice.loading = false;

						this.classPrice.errorMessage = error.response != null  && error.response.data != null ? error.response.data.message : "Connection error";
						this.classPrice.errorShow = true

						if(error.response && error.response.status == 401) {
							await localStorage.setItem("user_token", null);
							await localStorage.setItem("user_details", null)
							await localStorage.setItem("institution_details", null)

							this.$router.push(`/sign-in`);
						}
					});

				}else{
					this.notify('Price must range from 5,000/=Tzs - 50,000/=Tzs', 'error')
				}
            },


			notify(message, type) {
                notification[type]({
                    message: message,
                    description: '',
                    placement: 'topRight',
                    duration: 3,
                });
                
            },

		},
	})

</script>

<style lang="scss" scoped>
	
</style>